<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-2 pt-6 pb-0">
            <div class="card-title">
              <h4 class="ml-3 mt-2 mr-2">Ref:</h4>
              <span class="text-success">{{ ad.code }}</span>
            </div>
            <div class="card-body">
              <b class="text-info"
                >Publié(e) le : <span class="text-info"> {{ date }}</span>
              </b>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->
              <div
                class="btn-group"
                v-if="ad.date_validation === null && ad.date_rejet === null"
              >
                <button
                  class="btn btn-success btn-md mr-3"
                  value="Valider l'annonce"
                  @click="acceptAd(ad)"
                >
                  <i class="fas fa-check"></i>
                </button>
                <div
                  class="btn btn-danger btn-md"
                  title="Rejeter l'annonce"
                  @click="rejectAd(ad)"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
              <!--end::Button-->
              <div class="card-text" v-if="ad.date_rejet">
                <h3 class="badge badge-danger">
                  Annonce à été rejetée
                </h3>
                <span>Date de reject: {{ dateVisite(ad.date_rejet) }}</span>
                <a href="#" @click.prevent="infoReject(ad)" class="btn btn-link"
                  >Voir les détails</a
                >
              </div>
              <div class="card-text" v-if="ad.date_validation">
                <h3 class="badge badge-success mr-2">Annonce à été acceptée</h3>
                <span
                  >Date de validation:
                  {{ dateVisite(ad.date_validation) }}</span
                >
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card shadow rounded">
                  <div style="height: 400px;">
                    <hooper group="group1" class="mb-3 h-100 rounded">
                      <slide
                        v-for="(slide, indx) in images"
                        :key="indx"
                        :index="indx"
                      >
                        <img :src="slide.url" class="w-100 h-100" />
                      </slide>
                    </hooper>
                  </div>

                  <hooper group="group1" :settings="hooperSettings">
                    <slide
                      v-for="(slide, indx) in images"
                      :key="indx"
                      :index="indx"
                    >
                      <img :src="slide.url" height="100%" />
                    </slide>
                    <hooper-navigation slot="hooper-addons"></hooper-navigation>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                    <hooper-progress slot="hooper-addons"></hooper-progress>
                  </hooper>
                </div>
                <div class="bg-light card shadow rounded">
                  <div class="card-body" v-show="documents">
                    <table class="table">
                      <tr
                        class="row"
                        v-for="(document, index) in documents"
                        :key="index"
                      >
                        <th class="col-sm-6">Document {{ index + 1 }}</th>
                        <td class="col-sm-6">
                          <a
                            :href="document.url"
                            download="plan.pdf"
                            class="btn btn-link"
                            style="padding: 0;"
                            ><i class="fas fa-download"></i> télécharger</a
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="bg-light card shadow rounded">
                  <div class="card-header d-flex justify-content-between">
                    <h4 class="my-1 card-title">Infos sur le propriétaire</h4>
                    <b-button
                      size="sm"
                      variant="success"
                      v-if="userInfo"
                      @click="showInfoUser"
                      ><i class="fas fa-minus text-white"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      variant="warning"
                      v-else
                      @click="showInfoUser"
                      ><i class="fas fa-plus text-white"></i
                    ></b-button>
                  </div>
                  <div class="card-body" v-if="userInfo">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Nom</th>
                        <td class="col-sm-6">{{ ad.user.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Prenoms</th>
                        <td class="col-sm-6">{{ ad.user.prenoms }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Email</th>
                        <td class="col-sm-6">{{ ad.user.email }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Numéros de téléphone</th>
                        <td class="col-sm-6">{{ ad.user.telephone_1 }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Role</th>
                        <td class="col-sm-6">
                          {{ ad.user.roles[0].slug }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="card bg-light mt-5 shadow rounded">
                  <div class="card-header d-flex justify-content-between">
                    <h4 class="my-1 card-title">Infos sur l'annonce</h4>
                    <div
                      class="btn btn-success btn-sm"
                      v-if="adInfo"
                      @click="showInfoAd"
                    >
                      <i class="fas fa-minus text-white"></i>
                    </div>
                    <div
                      class="btn btn-bg-warning btn-xs"
                      v-else
                      @click="showInfoAd"
                    >
                      <i class="fas fa-plus text-white"></i>
                    </div>
                  </div>
                  <div class="card-body" v-if="adInfo">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Superficie</th>
                        <td class="col-sm-6">{{ ad.superficie }} m²</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Quartier</th>
                        <td class="col-sm-6">{{ ad.quartier.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Type de biens</th>
                        <td class="col-sm-6">{{ ad.type.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Ville</th>
                        <td class="col-sm-6">{{ ad.ville.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Type</th>
                        <td class="col-sm-6" v-if="ad.location === true">
                          A Louer
                        </td>
                        <td class="col-sm-6" v-else>A Vendre</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Etat</th>
                        <td class="col-sm-6" v-if="ad.valider">
                          Annonce Valide
                        </td>
                        <td class="col-sm-6" v-else>Annonce Invalide</td>
                      </tr>
                      <tr class="row" v-if="ad.valider">
                        <th class="col-sm-6">Date de validation</th>
                        <td class="col-sm-6">{{ ad.date_validation }}</td>
                      </tr>
                      <tr class="row" v-else>
                        <th class="col-sm-6">Date de rejet</th>
                        <td class="col-sm-6">{{ ad.date_rejet }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Latitude</th>
                        <td class="col-sm-6">{{ ad.lat }}°</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Longitude</th>
                        <td class="col-sm-6">{{ ad.long }}°</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="card bg-light mt-5 shadow rounded">
                  <div class="card-header d-flex justify-content-between">
                    <h4 class="my-1 card-title">Infos sur les prix</h4>
                    <div
                      class="btn btn-success btn-xs"
                      v-if="adPrice"
                      @click="showAdPrice"
                    >
                      <i class="fas fa-minus text-white"></i>
                    </div>
                    <div
                      class="btn btn-bg-warning btn-xs"
                      v-else
                      @click="showAdPrice"
                    >
                      <i class="fas fa-plus text-white"></i>
                    </div>
                  </div>
                  <div class="card-body" v-if="adPrice">
                    <table class="table">
                      <tr>
                        <th>Prix standard</th>
                        <td>{{ ad.prix_standard }} Fcfa</td>
                      </tr>
                      <tr>
                        <th>Prix final</th>
                        <td>{{ ad.prix_final }} Fcfa</td>
                      </tr>
                      <tr>
                        <th>Cout mensuel</th>
                        <td>{{ ad.cout_mensuel }} Fcfa</td>
                      </tr>
                      <tr>
                        <th>Montant caution</th>
                        <td>{{ ad.montant_caution }} Fcfa</td>
                      </tr>
                      <tr>
                        <th>Avance</th>
                        <td>{{ ad.avance }} mois</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="card bg-light mt-5 shadow rounded">
                  <div class="card-header d-flex justify-content-between">
                    <h4 class="my-1 card-title">Autres information</h4>
                    <div
                      class="btn btn-success btn-xs"
                      v-if="otherDetails"
                      @click="showOtherDetails"
                    >
                      <i class="fas fa-minus text-white"></i>
                    </div>
                    <div
                      class="btn btn-bg-warning btn-xs"
                      v-else
                      @click="showOtherDetails"
                    >
                      <i class="fas fa-plus text-white"></i>
                    </div>
                  </div>
                  <div class="card-body" v-if="otherDetails">
                    <table class="table">
                      <tr>
                        <th>Nombre de chambre</th>
                        <td>{{ ad.nbre_chambre }} Chambre</td>
                      </tr>
                      <tr>
                        <th>Cuisine interne</th>
                        <td v-if="ad.cuisine_int" class="badge badge-success">
                          {{ ad.nbre_cuisine_int }}
                        </td>
                        <td v-else class="badge badge-danger">0</td>
                      </tr>
                      <tr>
                        <th>Cuisine externe</th>
                        <td v-if="ad.cuisine_ext" class="badge badge-success">
                          {{ ad.nbre_cuisine_ext }}
                        </td>
                        <td v-else class="badge badge-danger">0</td>
                      </tr>
                      <tr>
                        <th>WC douche interne</th>
                        <td v-if="ad.wc_douche_int" class="badge badge-success">
                          {{ ad.nbre_wc_douche_int }}
                        </td>
                        <td v-else class="badge badge-danger">0</td>
                      </tr>
                      <tr>
                        <th>WC douche externe</th>
                        <td v-if="ad.wc_douche_ext" class="badge badge-success">
                          {{ ad.nbre_wc_douche_ext }}
                        </td>
                        <td v-else class="badge badge-danger">0</td>
                      </tr>
                      <tr>
                        <th>Cour</th>
                        <td v-if="ad.cour" class="badge badge-success">Oui</td>
                        <td v-else class="badge badge-danger">Non</td>
                      </tr>
                      <tr>
                        <th>Terasse</th>
                        <td v-if="ad.terasse" class="badge badge-success">
                          {{ ad.nbre_terasse }}
                        </td>
                        <td v-else class="badge badge-danger">0</td>
                      </tr>
                      <tr>
                        <th>Etage</th>
                        <td v-if="ad.etage" class="badge badge-success">
                          {{ ad.num_etage }}
                        </td>
                        <td v-else class="badge badge-danger">0</td>
                      </tr>
                      <tr>
                        <th>Balcon</th>
                        <td v-if="ad.balcon" class="badge badge-success">
                          Oui
                        </td>
                        <td v-else class="badge badge-danger">Non</td>
                      </tr>
                      <tr>
                        <th>Meuble</th>
                        <td v-if="ad.meuble" class="badge badge-success">
                          Oui
                        </td>
                        <td v-else class="badge badge-danger">Non</td>
                      </tr>
                      <tr>
                        <th>Climatiseurs</th>
                        <td v-if="ad.climatiseurs" class="badge badge-success">
                          Oui
                        </td>
                        <td v-else class="badge badge-danger">Non</td>
                      </tr>
                      <tr>
                        <th>Puits</th>
                        <td v-if="ad.puits" class="badge badge-success">Oui</td>
                        <td v-else class="badge badge-danger">Non</td>
                      </tr>
                      <tr>
                        <th>Eau courante</th>
                        <td v-if="ad.eau_courante" class="badge badge-success">
                          Oui
                        </td>
                        <td v-else class="badge badge-danger">Non</td>
                      </tr>
                      <tr>
                        <th>Cloture</th>
                        <td v-if="ad.cloture" class="badge badge-success">
                          Oui
                        </td>
                        <td v-else class="badge badge-danger">Non</td>
                      </tr>
                      <tr>
                        <th>Garage</th>
                        <td v-if="ad.garage" class="badge badge-success">
                          {{ ad.nbre_garage }}
                        </td>
                        <td v-else class="badge badge-danger">0</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="card bg-light mt-5 shadow rounded">
                  <dl class="card-body">
                    <dt class="card-title">
                      <h5>Description de l'annonce</h5>
                    </dt>
                    <dd class="card-text">
                      <p v-html="ad.description"></p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid mt-5">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <div class="card shadow rounded">
                <div class="card-header">
                  <h4 class="card-title" style="margin: 0;">
                    Toutes les visites de l'annonce
                  </h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Demandeur</th>
                        <th>Date</th>
                        <th>Etats</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(visit, index) in visits" :key="index">
                        <td>{{ visit.user.nom }} {{ visit.user.prenoms }}</td>
                        <td>{{ dateVisite(visit.created_at) }}</td>
                        <td v-if="visit.etat === 0">
                          <span class="badge badge-dark">{{ etats["0"] }}</span>
                        </td>
                        <td v-if="visit.etat === 1">
                          <span class="badge badge-primary">{{
                            etats["1"]
                          }}</span>
                        </td>
                        <td v-if="visit.etat === 2">
                          <span class="badge badge-danger">{{
                            etats["2"]
                          }}</span>
                        </td>
                        <td v-if="visit.etat === 3">
                          <span class="badge badge-success">{{
                            etats["3"]
                          }}</span>
                        </td>
                        <td
                          class="btn-group"
                          v-if="visit.etat === 0 || visit.etat === 1"
                        >
                          <button
                            class="btn btn-info btn-sm"
                            @click="programVisit(visit)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn btn-danger btn-sm"
                            @click="rejectVisit(visit)"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            @click="termineVisit(visit)"
                            v-if="visit.etat !== 0"
                          >
                            <i class="fas fa-check"></i>
                          </button>
                        </td>
                        <td v-else-if="visit.etat === 2">
                          <button
                            class="btn btn-info btn-sm"
                            @click="infoRejectVisit(visit)"
                          >
                            <i class="fas fa-eye"></i> Voir détails
                          </button>
                        </td>
                        <td v-else>
                          <span class="badge badge-light"
                            ><i class="fas fa-check-double text-success"></i>
                            Visite terminée</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div class="card shadow rounded">
                <div class="card-header">
                  <h4 class="card-title" style="margin: 0;">
                    Toutes les propositions immobilières de l'annonce
                  </h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Demandeur</th>
                        <th>Prix</th>
                        <th>Date</th>
                        <th>Etats</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(proposition, index) in propositions"
                        :key="index"
                      >
                        <td>
                          {{ proposition.user.nom }}
                          {{ proposition.user.prenoms }}
                        </td>
                        <td>{{ proposition.proposition_prix }} Fcfa</td>
                        <td>{{ dateVisite(proposition.created_at) }}</td>
                        <td v-if="proposition.etat === 0">
                          <span class="badge badge-dark">{{ etats["0"] }}</span>
                        </td>
                        <td v-if="proposition.etat === 1">
                          <span class="badge badge-success">{{
                            etats["3"]
                          }}</span>
                        </td>
                        <td v-if="proposition.etat === 2">
                          <span class="badge badge-danger">{{
                            etats["2"]
                          }}</span>
                        </td>
                        <td class="btn-group" v-if="proposition.etat === 0">
                          <button
                            class="btn btn-danger btn-sm"
                            @click="rejectProps(proposition)"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            @click="acceptProps(proposition)"
                          >
                            <i class="fas fa-check"></i>
                          </button>
                        </td>
                        <td v-else-if="proposition.etat === 2">
                          <button
                            class="btn btn-info btn-sm"
                            @click="infoRejcetProps(proposition)"
                          >
                            <i class="fas fa-eye"></i> Voir détails
                          </button>
                        </td>
                        <td v-else>
                          <h4 class="badge badge-light">
                            <i class="fas fa-check-double text-success"></i>
                            Proposition acceptée
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card shadow rounded">
              <div class="card-header">
                <h4 class="card-title" style="margin: 0;">
                  Tous les tags de l'annonce
                </h4>
              </div>
              <div class="card-body">
                <div class="row justify-content-between">
                  <h4 class="col">
                    <span
                      class="badge badge-primary"
                      style="margin-right: 5px;margin-top: 5px;"
                      v-for="(tag, index) in tags"
                      :key="index"
                    >
                      #{{ tag.name }}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
// import {
//   Hooper,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination,
//   Progress as HooperProgress,
//   Slide
// } from "hooper";

export default {
  name: "Show",
  data() {
    return {
      initialize: "ad/" + this.$route.params.id,
      ad: [],

      adInfo: false,
      userInfo: false,
      adPrice: false,
      otherDetails: false,
      etats: {
        0: "En Attente...",
        1: "Progammer",
        2: "Rejeter",
        3: "Terminer"
      },

      hooperSettings: {
        itemsToShow: 3,
        centerMode: true
      },

      tags: [],
      visits: [],
      propositions: [],

      images: [],
      videos: [],
      documents: []
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    showInfoAd() {
      this.adInfo = !this.adInfo;
    },
    showInfoUser() {
      this.userInfo = !this.userInfo;
    },
    showAdPrice() {
      this.adPrice = !this.adPrice;
    },
    showOtherDetails() {
      this.otherDetails = !this.otherDetails;
    },
    dateVisite(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("-");
    },
    timeVisite(date) {
      let d = new Date(date);
      let hours = d.getHours();
      let minutes = d.getMinutes();
      let secondes = d.getSeconds();

      minutes = minutes < 10 ? "0" + minutes : minutes;

      return [hours, minutes, secondes].join(":");
    },
    rejectAd(ad) {
      this.reject(`ad/${ad.id}/reject`, "annonce");
    },
    infoReject(ad) {
      this.info(ad.note_validation);
    },
    infoRejectVisit(visit) {
      this.info(visit.autre);
    },
    infoRejcetProps(propImmo) {
      this.info(propImmo.description);
    },
    acceptAd(ad) {
      this.accept(`ad/${ad.id}/accept`, "Annonce");
    },
    reloadData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.ad = response.data.annonce;
        vm.images = response.data.annonce.images;
        vm.videos = response.data.annonce.videos;
        vm.documents = response.data.annonce.documents;
        vm.visits = response.data.visits;
        vm.propositions = response.data.propositions_immobilieres;
        vm.tags = response.data.annonce.tags;
      });
    },
    programVisit(visit) {
      Swal.fire({
        title: "Confirmation!",
        text: "Voulez vous vraiment programmer une visite ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          Swal.fire({
            title: "PROGRAMMER VISITE",
            html:
              '<input id="date" type="date" class="swal2-input">' +
              '<input id="time" type="time" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
              return [
                document.getElementById("date").value,
                document.getElementById("time").value
              ];
            }
          }).then(isConfirm => {
            if (isConfirm.isDismissed) {
              Swal.fire({
                title: "Ooups!",
                text: "Votre motif n'a pas été enregistré !",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            }
            if (isConfirm.isConfirmed) {
              ApiService.put(`visit/${visit.id}/program`, {
                date_prevue: isConfirm.value["0"],
                heure_prevue: isConfirm.value["1"]
              })
                .then(response => {
                  Swal.fire({
                    title: `Success`,
                    text: response.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  }).then(isConfirm => {
                    if (isConfirm) {
                      this.reloadData();
                    }
                  });
                })
                .catch(() => {
                  Swal.fire({
                    title: "Ooups!",
                    text:
                      "date et heure prevue doit être une date postérieure ou égale à la date d'aujourd'hui",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  });
                });
            } else {
              Swal.fire({
                title: "Erreur",
                text: "l'action n'a pas été effectuée !",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            }
          });
        }
      });
    },
    rejectVisit(visit) {
      this.reject(`visit/${visit.id}/reject`, "visite");
    },
    rejectProps(propImmo) {
      this.reject(`propsImmo/${propImmo.id}/reject`, "proposition immobilière");
    },
    termineVisit(visit) {
      this.accept(`visit/${visit.id}/end`, "Visite");
    },
    acceptProps(propImmo) {
      this.accept(`propsImmo/${propImmo.id}/accept`, "Proposition");
    },
    reject(url, name) {
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment rejeter cette ${name} ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Motifs",
        cancelButtonText: "Annuler",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          Swal.fire({
            input: "textarea",
            inputLabel: "Message",
            inputPlaceholder: "Saisir votre message ici...",
            inputAttributes: {
              "aria-label": "Saisir votre message ici"
            },
            inputValidator: value => {
              if (!value) {
                return "Veuillez saisir un motif svp!";
              }
            },
            showCancelButton: true
          }).then(isConfirm => {
            if (isConfirm.isDismissed) {
              Swal.fire({
                title: "Ooups!",
                text: "Votre motif n'a été enregistré !",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            }
            if (isConfirm.isConfirmed) {
              let response = ApiService.put(url, { motif: isConfirm.value });
              if (response) {
                Swal.fire({
                  title: `Success`,
                  text: "Votre motif a été enregistré avec success !",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown"
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp"
                  }
                });
                this.reloadData();
              }
            }
          });
        } else {
          Swal.fire({
            title: "Erreur",
            text: "l'action n'a pas été effectuée !",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
      });
    },
    accept(url, name) {
      let text = `accepter`;
      if (name === "Visite") text = "Terminer";
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment ${text} cette ${name} ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        cancelButtonText: "Annuler",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isDismissed) {
          Swal.fire({
            title: "Ooups!",
            text: "l'action n'a pas été effectuée !",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
        if (isConfirm.isConfirmed) {
          let response = ApiService.get(url);
          if (response) {
            Swal.fire({
              title: `${name} acceptée!`,
              text: "Action effectuée avec success",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            this.reloadData();
          }
        }
      });
    },
    info(data) {
      Swal.fire({
        title: "Motif du rejet",
        text: data,
        icon: "info",
        confirmButtonText: "Ok"
      });
    }
  },
  computed: {
    date: function formatDate() {
      let d = new Date(this.ad.created_at),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("-");
    }
  }
  // components: {
  //   Hooper,
  //   Slide,
  //   HooperNavigation,
  //   HooperPagination,
  //   HooperProgress
  // }
};
</script>

<style scoped></style>
